// Reference the font awesome icon library from Kyber js
// This library will have icons needed by all Kyber JS components
import {kyberFontAwesomeLibrary} from '@reasoncorp/kyber-js';

// import all icons the app will need here
import {
  faAngleDown,
  faAngleUp,
  faAward,
  faBan,
  faCheckCircle,
  faCog,
  faExclamationCircle,
  faMessage,
  faQuestionCircle,
  faFilePdf,
  faSearch,
  faSpinner,
  faTrash,
  faUserLock,
  faUserTimes
} from '@fortawesome/free-solid-svg-icons';

// Add any additional icons your app needs to the library.
// This avoids having to explicitly import them in your components.
kyberFontAwesomeLibrary.add(
  faAngleDown,
  faAngleUp,
  faAward,
  faBan,
  faCheckCircle,
  faCog,
  faExclamationCircle,
  faFilePdf,
  faMessage,
  faQuestionCircle,
  faSearch,
  faSpinner,
  faTrash,
  faUserTimes,
  faUserLock
);