import {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik} from 'formik';
import {escapeRegExp, range} from 'lodash';
import * as Yup from 'yup';

import {
  ButtonIcon,
  CustomTable,
  downloadUtils,
  formatUtils,
  FormikSearchInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {userRoleChangeApi} from '../api';
import {UserRoleChangeDto} from '../types';
import * as messages from '../messages';

const UserRoleChanges = () => {
  const [userRoleChangeDtos, setUserRoleChanges] = useState<UserRoleChangeDto[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedYear, setSelectedYear] = useState<number | null>(new Date().getFullYear());
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, saving: false});

  const filteredUserRoleChangeDtos = useMemo(() => {
    return userRoleChangeDtos
      .filter(userRoleChangeDto => userRoleChangeDto.year === selectedYear)
      .filter(userRoleChangeDto => {
        if (!searchText) {
          return true;
        } else {
          const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');

          return searchTermRegexp.test(userRoleChangeDto.user.firstName) ||
            searchTermRegexp.test(userRoleChangeDto.user.lastName) ||
            searchTermRegexp.test(userRoleChangeDto.user.username) ||
            searchTermRegexp.test(formatUtils.formatDate(userRoleChangeDto.createdAt) as string);
        }
      });
  }, [
    userRoleChangeDtos,
    searchText,
    selectedYear
  ]);

  useEffect(() => {
    const loadRoleChanges = async () => {
      try {
        const userRoleChangeDtos = await userRoleChangeApi.findAll();
        setUserRoleChanges(userRoleChangeDtos);
        setLoadingState({loading: false, loadError: false, saving: false});
      } catch (e) {
        showErrorAlert(messages.UNABLE_TO_CONNECT_SERVICE);
        setLoadingState({loading: false, loadError: true, saving: false});
      }
    };

    void loadRoleChanges();
  }, [
    showErrorAlert
  ]);

  const handleApprovalChange = useCallback(async (id: number, approved?: string) => {
    const isApproved = approved === '' ? null : (approved === 'true');
    setLoadingState({loading: false, loadError: false, saving: true});

    try {
      await userRoleChangeApi.update(id, {approved: isApproved});
      showSuccessAlert(messages.FORM_UPDATE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FORM_UPDATE_FAILURE);
    } finally {
      setLoadingState({loading: false, loadError: false, saving: false});
    }
  }, [
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleDownload = useCallback(async (id: number) => {
    try {
      await downloadUtils.downloadFile(userRoleChangeApi.getPdfSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.FORM_PDF_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Date', sortKey: 'createdAt', className: 'w-10'},
      {title: 'User', sortKey: 'user.fullName', className: 'align-middle w-40'},
      {title: 'Email', sortKey: 'user.username', className: 'align-middle w-25'},
      {title: 'View Form', className: 'align-middle text-center w-15'},
      {title: 'Approved', sortKey: 'approved', className: 'align-middle w-10'}
    ],
    items: filteredUserRoleChangeDtos,
    noResultsMessage: messages.FORMS_NOT_FOUND,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    renderRow: (userRoleChangeDto: UserRoleChangeDto) => {
      return <tr key={userRoleChangeDto.id}>
        <td className="align-middle">
          {formatUtils.formatDate(userRoleChangeDto.createdAt)}
        </td>
        <td className="align-middle">
          {userRoleChangeDto.user.fullName}
        </td>
        <td className="align-middle">
          {userRoleChangeDto.user.username}
        </td>
        <td className="text-center align-middle">
          <ButtonIcon ariaLabel={`View user ${userRoleChangeDto.user.fullName} 4689 Form`}
                      title={`View user ${userRoleChangeDto.user.fullName} 4689 Form`}
                      onClick={() => handleDownload(userRoleChangeDto.id)}
                      icon="file-pdf"/>
        </td>
        <td>
          <FormikSelect ariaLabel={`Update user ${userRoleChangeDto.user.fullName} status`}
                        formGroupClass="mb-0"
                        onChange={(e) => handleApprovalChange(
                          userRoleChangeDto.id,
                          e.target.value
                        )}
                        name={`roleChangesApproved['id_${userRoleChangeDto.id}']`}>
            <option value="">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </FormikSelect>
        </td>
      </tr>;
    }
  }), [
    handleApprovalChange,
    handleDownload,
    filteredUserRoleChangeDtos
  ]);

  const availableYears = useMemo(() => {
    const beginningYear = 2025;
    const years = range(beginningYear, new Date().getFullYear() + 1);
    return years.slice(0, 7);
  }, []);

  const initialValues = useMemo(() => {
    const roleChangesApproved: {[id: string]: boolean | null} = {};
    userRoleChangeDtos.forEach((userRoleChangeDto: UserRoleChangeDto) => {
      roleChangesApproved[`id_${userRoleChangeDto.id}`] = userRoleChangeDto.approved;
    });

    return {
      selectedYear,
      roleChangesApproved
    };
  }, [
    userRoleChangeDtos,
    selectedYear
  ]);

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && <>
        <Formik initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object().shape({})}
                onSubmit={() => {
                }}>
          {(_) => <>
            <Row>
              <Col className="col-1">
                <FormikSelect labelText="Year"
                              onChange={(e) => setSelectedYear(Number(e.target.value))}
                              name="selectedYear">
                  {availableYears.map(year => <option key={year} value={year}>{year}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    Form 4689 Request for Role Changes
                  </CardHeader>
                  <CardBody>
                    <FormikSearchInput disabled={loadingState.saving}
                                       onSubmit={setSearchText}/>
                    <CustomTable {...tableProps} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>}
        </Formik>
      </>}
    </Container>
  );
};

export default UserRoleChanges;