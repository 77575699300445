import {memo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers} from 'formik';

import {FormikPasswordInput, useAlerts} from '@reasoncorp/kyber-js';
import {passwordApi} from '../../api';
import * as messages from '../../messages';
import {UpdatePasswordFormFields} from '../../types/forms';
import {updatePasswordFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  onCancel: () => void
}

const UpdatePasswordModal = ({
                               isOpen,
                               onCancel
                             }: Props) => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const initialValues: UpdatePasswordFormFields = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = async (values: UpdatePasswordFormFields, formikHelpers: FormikHelpers<UpdatePasswordFormFields>) => {
    try {
      await passwordApi.updateCurrentUserPassword(values);
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      onCancel();
      showSuccessAlert(messages.USER_SAVE_SUCCESSFUL);
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<UpdatePasswordFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else {
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
        onCancel();
        showErrorAlert(messages.USER_SAVE_FAILED);
      }
    }
  };

  const handleCancel = (formikHelpers: FormikHelpers<UpdatePasswordFormFields>) => {
    formikHelpers.resetForm();
    onCancel();
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={updatePasswordFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               autoFocus={false}
               toggle={() => handleCancel(formikProps)}>
          <ModalHeader toggle={() => handleCancel(formikProps)}>Update Password</ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <FormikPasswordInput name="currentPassword"
                                       labelText="Current Password"
                                       autoComplete="off"
                                       autoFocus
                                       aria-required="true"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikPasswordInput name="password"
                                       labelText="New Password"
                                       autoComplete="new-password"
                                       aria-required="true"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikPasswordInput name="confirmPassword"
                                       labelText="Confirm Password"
                                       autoComplete="new-password"
                                       aria-required="true"/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button type="submit"
                      color="success"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Update
              </Button>
              <Button color="secondary"
                      onClick={() => handleCancel(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(UpdatePasswordModal);