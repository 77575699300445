import * as Yup from 'yup';

import {YupPhone} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {validateAddressComplete} from './validationUtils';

export default Yup.object().shape({
  id: Yup.number().required(messages.REQUIRED),
  email: Yup.string().email(messages.EMAIL_INVALID),
  street: Yup.string()
    .test(validateAddressComplete),
  city: Yup.string()
    .test(validateAddressComplete),
  state: Yup.string()
    .test(validateAddressComplete),
  zip: Yup.string()
    .test(validateAddressComplete),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT)
    .schema()
});