import {memo, useCallback, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';
import {sortBy} from 'lodash';

import {FormikSelect, Jurisdiction, JurisdictionRole, useAlerts} from '@reasoncorp/kyber-js';

import {useSsoAppContext} from '../../hooks';
import {AddRoleFormFields} from '../../types/forms';
import {addRoleFormSchema} from '../../schema';
import {RoleJurisdictionMapping} from '../../types';
import {userApi} from '../../api';
import * as messages from '../../messages';

type Props = {
  isOpen: boolean
  defaultEmail: string
  onToggle: (jurisdictionRole?: JurisdictionRole) => void
  userId: number
}

const AddRoleModal = ({
                        isOpen,
                        onToggle,
                        defaultEmail,
                        userId
                      }: Props) => {
  const {jurisdictionRoles, roleJurisdictionMappings} = useSsoAppContext();
  const {showErrorAlert} = useAlerts();
  const [selectableJurisdictions, setSelectableJurisdictions] = useState<Jurisdiction[]>([]);
  const initialValues: AddRoleFormFields = useMemo(() => ({roleId: '', jurisdictionId: ''}), []);

  const handleRoleChange = useCallback((roleId: string) => {
    const roleJurisdictionMapping = roleJurisdictionMappings
      .find(roleJurisdictionMapping => roleJurisdictionMapping.role.id === Number(roleId));
    const jurisdictions = sortBy(
      roleJurisdictionMapping?.jurisdictions ?? [],
      (jurisdiction: Jurisdiction) => jurisdiction.displayName
    );

    setSelectableJurisdictions(jurisdictions);
  }, [
    roleJurisdictionMappings
  ]);

  const handleSubmit = useCallback((values: AddRoleFormFields,
                                    formikHelpers: FormikHelpers<AddRoleFormFields>) => {
    const index = jurisdictionRoles.findIndex(jurisdictionRole => jurisdictionRole.jurisdiction.id === Number(values.jurisdictionId) &&
      jurisdictionRole.role.id === Number(values.roleId));
    // Default the email address to the user's email address which is their username
    onToggle({...jurisdictionRoles[index], email: defaultEmail});
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
  }, [defaultEmail, jurisdictionRoles, onToggle]);

  const handleClose = useCallback((formikProps: FormikProps<AddRoleFormFields>) => {
    formikProps.resetForm();
    onToggle();
  }, [onToggle]);

  const renderRoleOption = useMemo(() => (roleJurisdictionMapping: RoleJurisdictionMapping,
                                          index: number) =>
      <option key={index} value={roleJurisdictionMapping.role.id}>
        {roleJurisdictionMapping.role.displayName}
      </option>
    , []);

  const renderJurisdictionOption = useMemo(() => (jurisdiction: Jurisdiction, index: number) =>
      <option key={index} value={jurisdiction.id}>
        {jurisdiction.displayName}
      </option>
    , []);

  const handleJurisdictionChange = useCallback(async (roleId: number,
                                                      jurisdictionId: number,
                                                      setErrors: (errors: FormikErrors<AddRoleFormFields>) => void) => {
    const index = jurisdictionRoles.findIndex(jurisdictionRole => jurisdictionRole.jurisdiction.id === jurisdictionId &&
      jurisdictionRole.role.id === roleId);

    if (index >= 0) {
      try {
        await userApi.checkJurisdictionRoleMax(userId, {...jurisdictionRoles[index], email: defaultEmail});
      } catch (error: any) {
        const errorWithType = error as {status: number, validationMessages: FormikErrors<AddRoleFormFields>};
        if (errorWithType.status === 422 && errorWithType.validationMessages) {
          setErrors(errorWithType.validationMessages);
        } else {
          showErrorAlert(messages.UNABLE_TO_CONNECT_SERVICE);
        }
      }
    }
  }, [
    userId,
    showErrorAlert,
    defaultEmail,
    jurisdictionRoles
  ]);

  // This callback prevents onBlur from triggering revalidation of the jurisdiction select
  const handleJurisdictionBlur = useCallback(() => null, []);

  return (
    <Formik initialValues={initialValues}
            validationSchema={addRoleFormSchema}
            enableReinitialize
            validateOnMount
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               size="lg"
               autoFocus={false}
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>Add Role</ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <FormikSelect name="roleId"
                                labelText="Role"
                                aria-required={true}
                                autoFocus
                                onChange={(e) => handleRoleChange(e.target.value)}>
                    <option value="">Select</option>
                    {roleJurisdictionMappings.map(renderRoleOption)}
                  </FormikSelect>
                </Col>
              </Row>
              {formikProps.values.roleId && <Row>
                <Col>
                  <FormikSelect name="jurisdictionId"
                                labelText="Jurisdiction"
                                onBlur={handleJurisdictionBlur}
                                onChange={(e) => handleJurisdictionChange(
                                  Number(formikProps.values.roleId),
                                  Number(e.target.value),
                                  formikProps.setErrors
                                )}
                                aria-required={true}>
                    <option value="">Select</option>
                    {selectableJurisdictions.map(renderJurisdictionOption)}
                  </FormikSelect>
                </Col>
              </Row>}
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Add
              </Button>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(AddRoleModal);