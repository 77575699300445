import {memo, useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {sortBy} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  FormikInput,
  FormikPhoneInput,
  FormikRadioGroup,
  FormikSelect,
  Jurisdiction,
  MiSuiteRole,
  User,
  UsState
} from '@reasoncorp/kyber-js';

import {RoleChangeRequestFormFields, RoleChangeRequestType} from '../../types';
import {useSsoAppContext} from '../../hooks';
import {roleChangeRequestSchema} from '../../schema';

type Props = {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (values: RoleChangeRequestFormFields,
             helpers: FormikHelpers<RoleChangeRequestFormFields>) => Promise<void>
  user: User | undefined
}

const RoleChangeRequestModal = ({
                                  isOpen,
                                  onSubmit,
                                  onCancel,
                                  user
                                }: Props) => {
  const initialValues: RoleChangeRequestFormFields = useMemo(() => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    username: user?.username ?? '',
    address: {
      street: user?.address?.street ?? '',
      city: user?.address?.city ?? '',
      state: user?.address?.state ?? '',
      zip: user?.address?.zip ?? ''
    },
    roleAddress: {
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    roleEmail: '',
    rolePhoneNumber: '',
    roleId: null,
    jurisdictionId: null,
    jurisdictionRoleId: null,
    roleChangeType: null
  }), [
    user
  ]);
  const {roleJurisdictionMappings, getJurisdictionByRoleIdAndJurisdictionId} = useSsoAppContext();

  const handleCancel = useCallback(async (helpers: FormikHelpers<RoleChangeRequestFormFields>) => {
    helpers.resetForm();
    onCancel();
  }, [
    onCancel
  ]);

  const getRoleIdByRoleChangeType = useCallback((roleChangeType: RoleChangeRequestType) => {
    if (!roleChangeType) {
      return null;
    }

    const roleName = (roleChangeType === 'NEW_ASSESSOR_OF_RECORD' || roleChangeType === 'NO_LONGER_ASSESSOR_OF_RECORD') ?
      MiSuiteRole.ASSESSOR_OF_RECORD : MiSuiteRole.EQUALIZATION_DIRECTOR;
    return roleJurisdictionMappings.filter(rjMapping => rjMapping.role.name === roleName)?.[0]?.role?.id ?? -1;
  }, [
    roleJurisdictionMappings
  ]);

  const handleSubmit = useCallback(async (values: RoleChangeRequestFormFields,
                                          helpers: FormikHelpers<RoleChangeRequestFormFields>) => {
    values.roleId = getRoleIdByRoleChangeType(values.roleChangeType);
    values.jurisdictionRoleId = getJurisdictionByRoleIdAndJurisdictionId(
      Number(values.roleId),
      Number(values.jurisdictionId)
    ) || -1;
    await onSubmit(values, helpers);
  }, [
    onSubmit,
    getJurisdictionByRoleIdAndJurisdictionId,
    getRoleIdByRoleChangeType
  ]);

  const equalizationDirectorRoles = useMemo(() => {
    return user?.jurisdictionRoles
        ?.filter(jurisdictionRole => jurisdictionRole.role.name === MiSuiteRole.EQUALIZATION_DIRECTOR)
      ?? [];
  }, [
    user
  ]);

  const assessorOfRecordRoles = useMemo(() => {
    return user?.jurisdictionRoles
        ?.filter(jurisdictionRole => jurisdictionRole.role.name === MiSuiteRole.ASSESSOR_OF_RECORD)
      ?? [];
  }, [
    user
  ]);

  const getJurisdictionsForSelection = useCallback((roleChangeType: RoleChangeRequestType) => {
    if (roleChangeType === 'NO_LONGER_DIRECTOR_OF_RECORD') {
      return sortBy(
        equalizationDirectorRoles.map(jr => jr.jurisdiction),
        (jurisdiction: Jurisdiction) => jurisdiction.displayName
      );
    } else if (roleChangeType === 'NO_LONGER_ASSESSOR_OF_RECORD') {
      return sortBy(
        assessorOfRecordRoles.map(jr => jr.jurisdiction),
        (jurisdiction: Jurisdiction) => jurisdiction.displayName
      );
    } else if (roleChangeType === 'NEW_DIRECTOR_OF_RECORD' || roleChangeType === 'NEW_ASSESSOR_OF_RECORD') {
      const roleName = roleChangeType === 'NEW_DIRECTOR_OF_RECORD' ?
        MiSuiteRole.EQUALIZATION_DIRECTOR : MiSuiteRole.ASSESSOR_OF_RECORD;
      const roleJurisdictionMapping = roleJurisdictionMappings
        .find(roleJurisdictionMapping => roleJurisdictionMapping.role.name === roleName);
      const jurisdictions = roleJurisdictionMapping?.jurisdictions ?? [];

      return sortBy(jurisdictions, (jurisdiction: Jurisdiction) => jurisdiction.displayName);
    } else {
      return [];
    }
  }, [
    equalizationDirectorRoles,
    roleJurisdictionMappings,
    assessorOfRecordRoles
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={roleChangeRequestSchema}
            enableReinitialize
            validateOnMount
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               size="xl"
               autoFocus={false}
               toggle={() => handleCancel(formikProps)}>
          <ModalHeader toggle={() => handleCancel(formikProps)}>
            Form 4689 Role Change Request
          </ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody className="pt-0">
              <Row className="bg-light p-2 pl-1 mb-3 border-bottom">
                <Col className="font-weight-bold text-primary">
                  1. Please confirm your personal address information
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <FormikInput name="firstName"
                               autoFocus
                               labelText="First Name"
                               maxLength={50}
                               aria-required="true"/>
                </Col>
                <Col lg="3">
                  <FormikInput name="lastName"
                               labelText="Last Name"
                               maxLength={50}
                               aria-required="true"/>
                </Col>
                <Col lg="3">
                  <FormikPhoneInput name="phoneNumber"
                                    labelText="Phone Number"
                                    extensionLength={5}
                                    aria-required="true"/>
                </Col>
                <Col lg="3">
                  <FormikInput name="username"
                               disabled
                               labelText="Email Address"
                               aria-required="true"/>
                </Col>
              </Row>
              <Row>
                <Col lg="5">
                  <FormikInput name="address.street"
                               labelText="Street Address"
                               maxLength={100}
                               aria-required/>
                </Col>
                <Col lg="3">
                  <FormikInput name="address.city"
                               labelText="City"
                               maxLength={50}
                               aria-required/>
                </Col>
                <Col lg="2">
                  <FormikSelect name="address.state"
                                labelText="State"
                                aria-required>
                    <option value="">Select</option>
                    {Object.keys(UsState).map(state => {
                      return (
                        <option key={state} value={state}>{state}</option>
                      );
                    })}
                  </FormikSelect>
                </Col>
                <Col lg="2">
                  <FormikInput name="address.zip"
                               labelText="Zip Code"
                               maxLength={20}
                               aria-required/>
                </Col>
              </Row>
              <Row className="bg-light p-2 pl-1 mb-3 mt-3 border-top border-bottom">
                <Col className="font-weight-bold text-primary">
                  2. Please select the following update you would like to request:
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikRadioGroup inline
                                    name="roleChangeType"
                                    radioButtons={[
                                      {value: 'NEW_ASSESSOR_OF_RECORD', labelText: 'New Assessor of Record'},
                                      {
                                        value: 'NO_LONGER_ASSESSOR_OF_RECORD',
                                        labelText: 'No Longer Assessor of Record'
                                      },
                                      {value: 'NEW_DIRECTOR_OF_RECORD', labelText: 'New Director of Record'},
                                      {value: 'NO_LONGER_DIRECTOR_OF_RECORD', labelText: 'No Longer Director of Record'}
                                    ]}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikSelect name="jurisdictionId"
                                aria-required
                                disabled={!formikProps.values.roleChangeType}
                                labelText="Jurisdiction">
                    <option>Select</option>
                    {getJurisdictionsForSelection(formikProps.values.roleChangeType).map(jurisdiction =>
                      <option key={jurisdiction.id} value={jurisdiction.id}>{jurisdiction.displayName}</option>)}
                  </FormikSelect>
                </Col>
              </Row>
              {(formikProps.values.roleChangeType === 'NEW_ASSESSOR_OF_RECORD' ||
                formikProps.values.roleChangeType === 'NEW_DIRECTOR_OF_RECORD') && <>
                <Row className="bg-light p-2 pl-1 mb-3 mt-3 border-top border-bottom">
                  <Col className="font-weight-bold text-primary">
                    3. Please enter your jurisdiction contact information. Once new role is approved, this information will display in your MiSuite profile within the Roles section and within the county book.
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormikInput name="roleEmail"
                                 labelText="Email Address"/>
                  </Col>
                  <Col lg="6">
                    <FormikPhoneInput name="rolePhoneNumber"
                                      labelText="Phone Number"
                                      extensionLength={5}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg="5">
                    <FormikInput name="roleAddress.street"
                                 labelText="Street Address"
                                 maxLength={100}/>
                  </Col>
                  <Col lg="3">
                    <FormikInput name="roleAddress.city"
                                 labelText="City"
                                 maxLength={50}/>
                  </Col>
                  <Col lg="2">
                    <FormikSelect name="roleAddress.state"
                                  labelText="State">
                      <option value="">Select</option>
                      {Object.keys(UsState).map(state => {
                        return (
                          <option key={state} value={state}>{state}</option>
                        );
                      })}
                    </FormikSelect>
                  </Col>
                  <Col lg="2">
                    <FormikInput name="roleAddress.zip"
                                 labelText="Zip Code"
                                 maxLength={20}/>
                  </Col>
                </Row>
              </>}
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                {formikProps.isSubmitting && <>
                  <FontAwesomeIcon icon="spinner"
                                   spin
                                   className="mr-1"/>
                  Submitting
                </>}
                {!formikProps.isSubmitting && 'Submit'}
              </Button>
              <Button color="secondary"
                      onClick={() => handleCancel(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(RoleChangeRequestModal);