import {memo} from 'react';
import {Badge, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {FormikInput, FormikSelect, UsState} from '@reasoncorp/kyber-js';

type Props = {
  className?: string
  addressRequired?: boolean
}

const PersonalAddressCard = ({
                               className,
                               addressRequired = false
                             }: Props) => {
  return (
    <Card className={className}>
      <CardHeader className="bg-secondary text-uppercase text-white">
        <Row>
          <Col>Personal Address</Col>
          <Col className="d-flex justify-content-end">
            <div>
              <Badge color="danger">Confidential</Badge>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg="5">
            <FormikInput name="address.street"
                         labelText="Street Address"
                         maxLength={100}
                         aria-required={addressRequired}/>
          </Col>
          <Col lg="3">
            <FormikInput name="address.city"
                         labelText="City"
                         maxLength={50}
                         aria-required={addressRequired}/>
          </Col>
          <Col lg="2">
            <FormikSelect name="address.state"
                          labelText="State"
                          aria-required={addressRequired}>
              <option value="">Select</option>
              {Object.keys(UsState).map(state => {
                return (
                  <option key={state} value={state}>{state}</option>
                );
              })}
            </FormikSelect>
          </Col>
          <Col lg="2">
            <FormikInput name="address.zip"
                         labelText="Zip Code"
                         maxLength={20}
                         aria-required={addressRequired}/>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(PersonalAddressCard);