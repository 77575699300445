import * as Yup from 'yup';

import {YupPhone} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {validateAddressComplete} from './validationUtils';

export default Yup.object().shape({
  firstName: Yup.string()
    .required(messages.REQUIRED),
  lastName: Yup.string()
    .required(messages.REQUIRED),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT)
    .schema()
    .required(messages.REQUIRED),
  username: Yup.string()
    .email(messages.EMAIL_INVALID)
    .required(messages.REQUIRED),
  address: Yup.object().shape({
    street: Yup.string()
      .required(messages.REQUIRED),
    city: Yup.string()
      .required(messages.REQUIRED),
    state: Yup.string()
      .required(messages.REQUIRED),
    zip: Yup.string()
      .required(messages.REQUIRED)
  }),
  roleChangeType: Yup.string()
    .required(messages.REQUIRED)
    .nullable()
    .oneOf([
      null,
      'NEW_ASSESSOR_OF_RECORD',
      'NO_LONGER_ASSESSOR_OF_RECORD',
      'NEW_DIRECTOR_OF_RECORD',
      'NO_LONGER_DIRECTOR_OF_RECORD'
    ]),
  roleEmail: Yup.string()
    .email(messages.EMAIL_INVALID),
  roleAddress: Yup.object().shape({
    street: Yup.string()
      .test(validateAddressComplete),
    city: Yup.string()
      .test(validateAddressComplete),
    state: Yup.string()
      .test(validateAddressComplete),
    zip: Yup.string()
      .test(validateAddressComplete)
  }),
  jurisdictionId: Yup.number()
    .nullable()
    .required(messages.REQUIRED),
  rolePhoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT)
    .schema()
});