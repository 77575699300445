import {MegProgramYear} from '@reasoncorp/kyber-js';

import {ssoApi} from './apiUtils';

export const downloadMegUserReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/meg/users'
);

export const downloadForm4022VerificationReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/form-4022/verification'
);

export const downloadForm4022CertificationReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/form-4022/certification'
);

export const downloadMiMessagingAuditReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/mi-messaging'
);

export const downloadLocalUnitFactorReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/local-unit-factor'
);

export const download4024StatewideReport = (year: number,
                                            reportType: 'PRELIMINARY' | 'ASSESSED' | 'EQUALIZED' | 'ADDED_TO_COUNTY') => ssoApi.restApiHelper.postWithBlobResponse(
  `/reports/misuite/statewide-4024/${year}?type=${reportType}`
);

export const findMegEqualizationYears = (): Promise<MegProgramYear[]> => ssoApi.restApiHelper.getWithJsonResponse(
  `/reports/misuite/meg/equalization-years`
);