import {TestContext} from 'yup';

import * as messages from '../messages';

export const validateAddressComplete = {
  message: messages.INCOMPLETE_ADDRESS_NOT_ALLOWED,
  test: (value: string | undefined, context: TestContext<Record<string, any>>) => {
    const addressHasContent = context.parent.street ||
      context.parent.city ||
      context.parent.state ||
      context.parent.zip;
    return !(addressHasContent && !value);
  }
};

