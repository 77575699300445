import {useCallback, useMemo} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {formatUtils, FormikCheckboxGroup, FormikDateInput} from '@reasoncorp/kyber-js';

import {AuditReportFormFields} from '../../types';
import {auditReportFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  onGenerate: (auditReportFormFields: AuditReportFormFields) => void
  onCancel: (response?: Response) => void
}

const AuditReportModal = ({
                            isOpen,
                            onGenerate,
                            onCancel
                          }: Props) => {
  const today = useMemo(() => {
    return new Date();
  }, []);

  const initialValues: AuditReportFormFields = useMemo(() => ({
    startDate: formatUtils.formatDateIso(new Date(today.getFullYear(), 0, 1)) || '',
    endDate: formatUtils.formatDateIso(today) || '',
    adminEventsOnly: false
  }), [
    today
  ]);

  const handleClose = useCallback((formikProps: FormikProps<AuditReportFormFields>) => {
    formikProps.resetForm();
    onCancel();
  }, [
    onCancel
  ]);

  const handleSubmit = useCallback(async (values: AuditReportFormFields, formikHelpers: FormikHelpers<AuditReportFormFields>) => {
    try {
      onGenerate(values);
      formikHelpers.resetForm();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    onGenerate
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={auditReportFormSchema}
            validateOnMount
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>User Audit Report</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormikDateInput name="startDate"
                                 labelText="Start Date"
                                 maxDate={today}
                                 aria-required="true"/>
              </Col>
              <Col>
                <FormikDateInput name="endDate"
                                 labelText="End Date"
                                 maxDate={today}
                                 aria-required="true"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikCheckboxGroup formGroupClass="mt-1 mb-0"
                                     checkboxes={[
                                       {
                                         name: 'adminEventsOnly',
                                         labelText: 'Admin Events Only'
                                       }
                                     ]}/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.isValid || formikProps.isSubmitting}>
              Generate
            </Button>
            <Button color="secondary"
                    onClick={() => handleClose(formikProps)}
                    disabled={formikProps.isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

export default AuditReportModal;