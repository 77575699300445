import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormText, Label, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  FormikCheckboxGroup,
  FormikDateInput,
  FormikInput,
  FormikRadioGroup,
  FormikSelect,
  JurisdictionRole,
  ProgressIndicator,
  useAlerts,
  User
} from '@reasoncorp/kyber-js';

import branding from '../branding';
import {useSsoAppContext} from '../hooks';
import {authenticationApi, passwordApi, userApi} from '../api';
import * as messages from '../messages';
import {AddRoleModal} from '../components/editUser';
import {JurisdictionRoleCard, PersonalAddressCard, ProfileCard} from '../components/shared';
import {EditUserFormFields} from '../types/forms';
import {editUserFormSchema} from '../schema';

const EditUser = () => {
  const params = useParams() as {userId: string};
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {currentUser, setCurrentUser, isAdmin} = useSsoAppContext();
  const userId = parseInt(params.userId);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEnabledChangedModal, setShowEnabledChangedModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const breadcrumbs = useMemo(() => ([
    {text: 'Return to All Users', route: '/users'},
    {text: user ? `${user.firstName} ${user.lastName}` : '', active: true}
  ]), [
    user
  ]);

  const initialValues: EditUserFormFields = useMemo(() => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    username: user?.username ?? '',
    address: {
      street: user?.address?.street ?? '',
      city: user?.address?.city ?? '',
      state: user?.address?.state ?? '',
      zip: user?.address?.zip ?? ''
    },
    certifiedUser: user?.certifiedUser ? 'true' : 'false',
    certificationLevel: user?.certificationLevel ?? '',
    certificationNumber: user?.certificationNumber ?? '',
    certificationAttainedDate: user?.certificationAttainedDate ?? '',
    certificationExpiration: user?.certificationExpiration ?? '',
    jurisdictionRoles: user?.jurisdictionRoles ?? [],
    enabled: user?.enabled ?? false,
    accountNonLocked: user?.accountNonLocked ?? true,
    credentialsNonExpired: user?.credentialsNonExpired ?? true,
    profileInformation: {
      certificationsAttainedOn: {
        MCAT: user?.profileInformation?.certificationsAttainedOn?.MCAT ?? '',
        MCAO: user?.profileInformation?.certificationsAttainedOn?.MCAO ?? '',
        MAAO: user?.profileInformation?.certificationsAttainedOn?.MAAO ?? '',
        MMAO: user?.profileInformation?.certificationsAttainedOn?.MMAO ?? ''
      }
    }
  }), [
    user
  ]);

  const handlePasswordReset = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await passwordApi.reset(userId);
      // Reload the user since their account status values will be different now if their account was locked
      const user = await userApi.find(userId);
      setUser(user);
      showSuccessAlert(messages.PASSWORD_RESET_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.PASSWORD_RESET_FAILED);
    } finally {
      setShowResetPasswordModal(false);
    }
  }, [
    userId,
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleUserDelete = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await userApi.deleteUser(userId);
      showSuccessAlert(messages.USER_DELETE_SUCCESSFUL);
      navigate('/users');
    } catch (error) {
      showErrorAlert(messages.USER_DELETE_FAILED);
    } finally {
      setIsSubmitting(false);
      setIsDeleteModalOpen(false);
    }
  }, [
    navigate,
    userId,
    showSuccessAlert,
    showErrorAlert
  ]);

  const saveUser = useCallback(async (values: EditUserFormFields,
                                      formikHelpers: FormikHelpers<EditUserFormFields>) => {
    try {
      const usernameChanged = currentUser?.id === Number(user?.id) &&
        currentUser.username !== values.username;
      const currentUserChanged = currentUser?.id === Number(user?.id);
      const valuesToSubmit = {...values};

      // Set an all empty address to null before submitting. This prevents server side validation errors
      if (!values.address?.street && !values.address?.city && !values.address?.state && !values.address?.zip) {
        valuesToSubmit.address = null;
      }

      const updatedUser = await userApi.update(user?.id ?? -1, valuesToSubmit);
      setUser(updatedUser);
      showSuccessAlert(messages.USER_SAVE_SUCCESSFUL);

      if (usernameChanged) {
        alert(messages.SIGN_IN_WITH_NEW_EMAIL);
        await authenticationApi.signOut();
      }
      if (currentUserChanged) {
        const currentUser = await userApi.findCurrent();
        setCurrentUser(currentUser);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<EditUserFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        formikHelpers.setErrors(errorWithType.validationMessages);
      }
      showErrorAlert(messages.USER_SAVE_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    currentUser,
    setCurrentUser,
    user,
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleSubmit = useCallback(async (values: EditUserFormFields,
                                          formikHelpers: FormikHelpers<EditUserFormFields>) => {
    // Require the user to confirm the want to change the enabled status before saving the user
    const enabledChanged = user?.enabled !== values.enabled;
    if (!enabledChanged) {
      await saveUser(values, formikHelpers);
    } else {
      setShowEnabledChangedModal(true);
    }
  }, [
    user,
    saveUser
  ]);

  const toggleAddRoleModal = useCallback((jurisdictionRole?: JurisdictionRole,
                                          formikProps?: FormikProps<EditUserFormFields>) => {
    if (jurisdictionRole && formikProps) {
      const index = formikProps.values.jurisdictionRoles
        .findIndex(existingJurisdictionRole => existingJurisdictionRole.id === jurisdictionRole.id);
      // Don't add something that is already there
      if (index === -1) {
        const newJurisdictionRoles = [...formikProps.values.jurisdictionRoles];
        newJurisdictionRoles.push(jurisdictionRole);
        formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles);
      }
    }
    setShowAddRoleModal(prevShowAddRoleModal => !prevShowAddRoleModal);
  }, []);

  const handleConfirmEnabledChange = useCallback(async (formikProps: FormikProps<EditUserFormFields>) => {
    setIsSubmitting(true);
    await saveUser(formikProps.values, formikProps);
    setShowEnabledChangedModal(false);
    setIsSubmitting(false);
  }, [
    saveUser
  ]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await userApi.find(userId);
        setUser(user);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_USER);
      }
    };

    void loadUser();
  }, [
    userId,
    isAdmin,
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
          <Formik initialValues={initialValues}
                  validationSchema={editUserFormSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}>
            {(formikProps) => (
              <Form autoComplete="off">
                <Row className="mb-3 d-flex align-items-center">
                  <Col sm="12" md="8" className="d-flex align-items-center">
                    {isAdmin &&
                      <FormikCheckboxGroup labelText=""
                                           type="switch"
                                           checkboxes={[{name: 'enabled', labelText: 'Enable'}]}
                                           formGroupClass="mb-0 mr-3"/>
                    }
                    <Label className="mr-1 mb-0">Account Locked:</Label>
                    <span className={user?.accountNonLocked ? 'text-success' : 'text-danger'}>
                      {user?.accountNonLocked ? 'No' : 'Yes'}
                    </span>
                    <Label className="ml-3 mr-1 mb-0">Credentials Expired:</Label>
                    <span className={user?.credentialsNonExpired ? 'text-success' : 'text-danger'}>
                      {user?.credentialsNonExpired ? 'No' : 'Yes'}
                    </span>
                  </Col>
                  <Col sm="12" md="4" className="d-flex justify-content-md-end">
                    {isAdmin && branding.name === 'MISUITE' &&
                      <Button color="danger"
                              className="mr-2"
                              onClick={() => setIsDeleteModalOpen(true)}
                              disabled={formikProps.isSubmitting}>
                        Delete User
                      </Button>}
                    <Button color="primary"
                            className={isAdmin ? 'mr-2' : ''}
                            onClick={() => setShowResetPasswordModal(true)}
                            disabled={formikProps.isSubmitting || !user?.enabled}>
                      Reset Password
                    </Button>
                    {isAdmin &&
                      <Button color="primary"
                              onClick={() => toggleAddRoleModal()}
                              disabled={formikProps.isSubmitting || !user?.enabled}>
                        Add Role
                      </Button>}
                    <Button color="success"
                            className="ml-2"
                            onClick={formikProps.submitForm}
                            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                      Save
                    </Button>
                  </Col>
                </Row>
                <ProfileCard className="mb-4"/>
                {branding.certificationLevels.length !== 0 &&
                  <Card className="mb-4">
                    <CardHeader className="bg-secondary text-uppercase text-white">Certification</CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormikRadioGroup name="certifiedUser"
                                            inline
                                            radioButtons={[
                                              {labelText: 'Certified User', value: 'true'},
                                              {labelText: 'Uncertified User', value: 'false'}
                                            ]}
                                            disabled={!isAdmin}/>
                        </Col>
                      </Row>
                      {formikProps.values.certifiedUser === 'true' &&
                        <>
                          <Row>
                            <Col lg="3">
                              <FormikSelect name="certificationLevel"
                                            labelText="Certification Level"
                                            disabled={!isAdmin}>
                                <option value="">Select</option>
                                <option value="MCAT">MCAT</option>
                                <option value="MCAO">MCAO</option>
                                <option value="MAAO">MAAO</option>
                                <option value="MMAO">MMAO</option>
                              </FormikSelect>
                            </Col>
                            <Col lg="3">
                              <FormikInput formGroupClass="mb-2"
                                           maxLength={50}
                                           name="certificationNumber"
                                           labelText="Certification Number"
                                           disabled={!isAdmin}/>
                            </Col>
                            <Col lg="3">
                              <FormikDateInput name="certificationAttainedDate"
                                               labelText="Initial Certification Attained"
                                               disabled={!isAdmin}/>
                            </Col>
                            <Col lg="3">
                              <FormikDateInput name="certificationExpiration"
                                               formGroupClass="mb-0"
                                               labelText="Certification Expiration"
                                               disabled/>
                              <FormText>Certification expiration is managed by the system.</FormText>
                            </Col>
                          </Row>
                          <Row className="mt-2 pt-2">
                            {Object.entries(formikProps.values.profileInformation.certificationsAttainedOn).map(([key]) => (
                              <Col lg="3">
                                <FormikDateInput name={`profileInformation.certificationsAttainedOn.${key}`}
                                                 labelText={`${key} Certification Date`}
                                                 disabled={!isAdmin}/>
                              </Col>
                            ))}
                          </Row>
                        </>
                      }
                    </CardBody>
                  </Card>
                }
                <PersonalAddressCard className="mb-4"/>
                <JurisdictionRoleCard className="mb-4"
                                      adminView={isAdmin}
                                      defaultEmail={user?.username ?? ''}
                                      jurisdictionRoles={formikProps.values.jurisdictionRoles}
                                      setJurisdictionRoles={(newJurisdictionRoles) => formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles)}
                                      disabled={formikProps.isSubmitting}
                                      jurisdictionRolesError={formikProps.errors.jurisdictionRoles?.toString() ?? ''}/>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="success"
                            onClick={formikProps.submitForm}
                            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                      Save
                    </Button>
                  </Col>
                </Row>
                {isAdmin &&
                  <AddRoleModal isOpen={showAddRoleModal}
                                defaultEmail={user?.username ?? ''}
                                userId={user?.id ?? -1}
                                onToggle={(jurisdictionRole) => toggleAddRoleModal(jurisdictionRole, formikProps)}/>
                }
                {isAdmin &&
                  <ConfirmationModal isOpen={showEnabledChangedModal}
                                     size="lg"
                                     title={formikProps.values.enabled ? 'Enable User' : 'Disable User'}
                                     confirmButtonText="Yes"
                                     cancelButtonText="No"
                                     confirmCallback={() => handleConfirmEnabledChange(formikProps)}
                                     cancelCallback={() => {
                                       // Formik will have put the form in submitting state when save was. Set to not submitting if user doesn't confirm
                                       formikProps.setSubmitting(false);
                                       setShowEnabledChangedModal(false);
                                     }}
                                     confirmButtonDisabled={isSubmitting}
                                     cancelButtonDisabled={isSubmitting}>
                    <p>
                      {formikProps.values.enabled ?
                        <span>Are you sure you want to enable <span className="text-danger">{`${user?.firstName} ${user?.lastName}`}</span>?</span>
                        :
                        <span>Are you sure you want to disable <span className="text-danger">{`${user?.firstName} ${user?.lastName}`}</span>? Disabling user will remove all roles currently assigned.</span>
                      }
                    </p>
                  </ConfirmationModal>
                }
              </Form>
            )}
          </Formik>
          <ConfirmationModal isOpen={showResetPasswordModal}
                             size="lg"
                             title="Reset Password"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             confirmCallback={() => handlePasswordReset()}
                             cancelCallback={() => setShowResetPasswordModal(false)}
                             confirmButtonDisabled={isSubmitting}
                             cancelButtonDisabled={isSubmitting}>
            <p>
              Are you sure you want to reset the password for <span className="text-danger">{user ? `${user.firstName} ${user.lastName}` : ''}</span>?
            </p>
          </ConfirmationModal>
          <ConfirmationModal isOpen={isDeleteModalOpen}
                             size="lg"
                             title="Delete User"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             confirmCallback={handleUserDelete}
                             cancelCallback={() => setIsDeleteModalOpen(false)}
                             confirmButtonDisabled={isSubmitting}
                             cancelButtonDisabled={isSubmitting}>
            <p>
              Are you sure you want to delete
              <span className="text-danger"> {user?.firstName} {user?.lastName}</span>?
              Deleting user will remove all roles currently assigned and MiSuite access.
            </p>
          </ConfirmationModal>
        </>
        }
      </Container>
    );
  }
};

export default EditUser;