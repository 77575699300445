import {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav} from '@reasoncorp/kyber-js';

import {userApplicationApi} from '../api';
import {Application} from '../types';
import {ApplicationCard} from '../components';

const Dashboard = () => {
  const [applicationList, setApplicationList] = useState<Application[]>([]);

  const breadcrumbs = useMemo(() => ([
    {text: 'Dashboard', active: true}
  ]), []);

  useEffect(() => {
    const loadData = async () => {
      const applicationList = await userApplicationApi.applications();
      setApplicationList(applicationList);
    };
    loadData().then();
  }, []);

  return (
    <Container fluid>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Row>
        {applicationList.map((application, index) =>
          <Col xs="12" sm="6" md="4" lg="3" className="mb-3" key={index}>
            <ApplicationCard application={application}/>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;