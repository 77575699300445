import {memo, useCallback, useMemo} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {Button, Col, Form, FormText, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

import {FormikInput, FormikPhoneInput, FormikSelect, JurisdictionRole, UsState} from '@reasoncorp/kyber-js';

import {jurisdictionRoleFormSchema} from '../../schema';
import {JurisdictionRoleFormFields} from '../../types/forms';

type Props = {
  isOpen: boolean
  jurisdictionRole: JurisdictionRole
  onToggle: (jurisdictionRole?: JurisdictionRole) => void
};

const JurisdictionRoleModal = ({
                                 isOpen,
                                 jurisdictionRole,
                                 onToggle
                               }: Props) => {
  const initialValues: JurisdictionRoleFormFields = useMemo(() => ({
    id: jurisdictionRole.id,
    email: jurisdictionRole.email ?? '',
    street: jurisdictionRole.address?.street ?? '',
    city: jurisdictionRole.address?.city ?? '',
    state: jurisdictionRole.address?.state ?? '',
    zip: jurisdictionRole.address?.zip ?? '',
    phoneNumber: jurisdictionRole.phoneNumber ?? ''
  }), [
    jurisdictionRole
  ]);

  const handleSubmit = useCallback((jurisdictionRoleFormFields: JurisdictionRoleFormFields,
                                    formikHelpers: FormikHelpers<JurisdictionRoleFormFields>) => {
    const updatedJurisdictionRole = {
      ...jurisdictionRole,
      email: jurisdictionRoleFormFields.email,
      phoneNumber: jurisdictionRoleFormFields.phoneNumber
    };

    // If any address values are blank set the entire address to null.  The user can figure it out from there what they screwed up on.
    if (!jurisdictionRoleFormFields.street ||
      !jurisdictionRoleFormFields.city ||
      !jurisdictionRoleFormFields.street ||
      !jurisdictionRoleFormFields.zip) {
      updatedJurisdictionRole.address = null;
    } else {
      updatedJurisdictionRole.address = {
        street: jurisdictionRoleFormFields.street,
        city: jurisdictionRoleFormFields.city,
        state: jurisdictionRoleFormFields.state,
        zip: jurisdictionRoleFormFields.zip
      };
    }

    formikHelpers.setSubmitting(false);
    onToggle(updatedJurisdictionRole);
    formikHelpers.resetForm();
  }, [
    onToggle,
    jurisdictionRole
  ]);

  return <Formik initialValues={initialValues}
                 validationSchema={jurisdictionRoleFormSchema}
                 enableReinitialize={true}
                 onSubmit={handleSubmit}>
    {(formikProps) => (
      <Modal isOpen={isOpen}
             backdrop="static"
             autoFocus={false}
             toggle={() => onToggle()}>
        <ModalHeader toggle={() => onToggle()}>
          Edit Role Information
        </ModalHeader>
        <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
          <ModalBody>
            <Row>
              <Col>
                <FormText color="muted">Removing an email address will default it to your login email address.</FormText>
                <FormikInput name="email"
                             labelText="Email Address"
                             autoFocus
                             disabled={formikProps.isSubmitting}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="street"
                             labelText="Street Address"
                             maxLength={100}
                             disabled={formikProps.isSubmitting}/>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormikInput name="city"
                             labelText="City"
                             maxLength={50}
                             disabled={formikProps.isSubmitting}/>
              </Col>
              <Col lg="4">
                <FormikSelect name="state"
                              labelText="State"
                              disabled={formikProps.isSubmitting}>
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state => <option key={state} value={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col lg="4">
                <FormikInput name="zip"
                             labelText="Zip"
                             maxLength={20}
                             disabled={formikProps.isSubmitting}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikPhoneInput name="phoneNumber"
                                  labelText="Phone Number"
                                  id="rolePhoneNumberInput"
                                  extensionLength={5}/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
              Update
            </Button>
            <Button color="secondary"
                    onClick={() => onToggle()}
                    disabled={formikProps.isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )}
  </Formik>;
};

export default memo(JurisdictionRoleModal);