import {User} from '@reasoncorp/kyber-js';

import {UserRoleChangeDto, UserRoleChangeRequest} from '../types';
import {ssoApi} from './apiUtils';

export const create = (roleChangeRequest: UserRoleChangeRequest): Promise<User> => ssoApi.restApiHelper.postWithJsonResponse(
  'users/role-changes',
  {
    body: JSON.stringify(roleChangeRequest)
  }
);

export const findAll = (): Promise<UserRoleChangeDto[]> => ssoApi.restApiHelper.getWithJsonResponse(
  'users/role-changes'
);

export const update = (id: number, values: {
  approved: boolean | null
}): Promise<UserRoleChangeDto[]> => ssoApi.restApiHelper.patchWithJsonResponse(
  `users/role-changes/${id}`,
  {
    body: JSON.stringify(values)
  }
);

export const getPdfSignedUrl = (id: number): Promise<string> => ssoApi.restApiHelper.getWithTextResponse(
  `users/role-changes/${id}/pdf`
);